import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Percentage = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2728 0.804237C17.5511 1.15244 17.4944 1.66029 17.1462 1.93855C10.6757 7.10933 7.27673 10.2896 1.9449 17.1381C1.67108 17.4898 1.16399 17.553 0.812285 17.2791C0.460577 17.0053 0.397437 16.4982 0.671257 16.1465C6.0964 9.1782 9.59098 5.9099 16.1385 0.677592C16.4867 0.399332 16.9945 0.456032 17.2728 0.804237Z"
      fill="#777777"
    />
    <path
      d="M6.68858 4.26739C6.68858 5.60458 5.60458 6.68858 4.26739 6.68858C2.9302 6.68858 1.84619 5.60458 1.84619 4.26739C1.84619 2.9302 2.9302 1.84619 4.26739 1.84619C5.60458 1.84619 6.68858 2.9302 6.68858 4.26739Z"
      fill="#EDC14F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.30259 4.26726C8.30259 6.49591 6.49591 8.30259 4.26726 8.30259C2.03861 8.30259 0.231934 6.49591 0.231934 4.26726C0.231934 2.03861 2.03861 0.231934 4.26726 0.231934C6.49591 0.231934 8.30259 2.03861 8.30259 4.26726ZM4.26726 6.68846C5.60445 6.68846 6.68846 5.60445 6.68846 4.26726C6.68846 2.93007 5.60445 1.84606 4.26726 1.84606C2.93007 1.84606 1.84606 2.93007 1.84606 4.26726C1.84606 5.60445 2.93007 6.68846 4.26726 6.68846Z"
      fill="#777777"
    />
    <path
      d="M15.0279 13.4139C15.0279 14.7511 13.9439 15.8351 12.6067 15.8351C11.2696 15.8351 10.1855 14.7511 10.1855 13.4139C10.1855 12.0767 11.2696 10.9927 12.6067 10.9927C13.9439 10.9927 15.0279 12.0767 15.0279 13.4139Z"
      fill="#F4DA95"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6424 13.4142C16.6424 15.6429 14.8358 17.4496 12.6071 17.4496C10.3785 17.4496 8.57178 15.6429 8.57178 13.4142C8.57178 11.1856 10.3785 9.37891 12.6071 9.37891C14.8358 9.37891 16.6424 11.1856 16.6424 13.4142ZM12.6071 15.8354C13.9443 15.8354 15.0283 14.7514 15.0283 13.4142C15.0283 12.077 13.9443 10.993 12.6071 10.993C11.2699 10.993 10.1859 12.077 10.1859 13.4142C10.1859 14.7514 11.2699 15.8354 12.6071 15.8354Z"
      fill="#777777"
    />
  </SvgIcon>
);

export default Percentage;
