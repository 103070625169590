import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Users = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="30"
    viewBox="0 0 22 30"
    fill="none">
    <path
      d="M14.4206 6.07795V10.5566C14.4206 10.6983 14.3643 10.8343 14.2641 10.9345C14.1638 11.0347 14.0279 11.091 13.8861 11.091H10.0435C10.0002 11.0906 9.95727 11.0995 9.91764 11.117C9.87801 11.1345 9.84258 11.1603 9.81369 11.1926L9.1884 11.9034L9.23115 11.4063C9.23659 11.3354 9.21565 11.265 9.17236 11.2086V5.5542H13.9022C14.0393 5.55829 14.1696 5.61494 14.2661 5.71244C14.3626 5.80993 14.4179 5.9408 14.4206 6.07795Z"
      fill="#F4DA95"
    />
    <path
      d="M13.9021 4.95666H5.5862C5.28854 4.95666 5.00307 5.0749 4.79259 5.28538C4.58211 5.49586 4.46387 5.78133 4.46387 6.07899V10.5576C4.46527 10.8548 4.58397 11.1395 4.79414 11.3497C5.00432 11.5598 5.28897 11.6785 5.5862 11.6799H8.60045L8.52029 12.7488C8.51577 12.8121 8.53077 12.8752 8.56326 12.9297C8.59576 12.9842 8.64418 13.0274 8.702 13.0535C8.74099 13.059 8.78058 13.059 8.81957 13.0535C8.86046 13.0559 8.90141 13.05 8.93997 13.0362C8.97854 13.0225 9.01393 13.0011 9.04404 12.9733L10.1771 11.6693H13.9182C14.2154 11.6679 14.5 11.5492 14.7102 11.339C14.9204 11.1288 15.0391 10.8442 15.0405 10.5469V6.07899C15.0405 5.93024 15.0109 5.78298 14.9535 5.64575C14.8961 5.50853 14.812 5.38409 14.7061 5.27966C14.6002 5.17523 14.4746 5.09289 14.3365 5.03744C14.1985 4.98199 14.0509 4.95453 13.9021 4.95666ZM14.4366 10.5576C14.4366 10.6994 14.3803 10.8353 14.28 10.9355C14.1798 11.0358 14.0439 11.0921 13.9021 11.0921H10.0434C10.0001 11.0917 9.95722 11.1005 9.91759 11.118C9.87796 11.1355 9.84252 11.1613 9.81364 11.1936L9.18834 11.9044L9.23109 11.4074C9.23504 11.3233 9.20632 11.241 9.15093 11.1776C9.09172 11.1172 9.01104 11.0826 8.92646 11.0814H5.5862C5.44445 11.0814 5.30852 11.0251 5.20829 10.9248C5.10806 10.8246 5.05175 10.6887 5.05175 10.5469V6.07899C5.05175 5.93724 5.10806 5.80131 5.20829 5.70108C5.30852 5.60085 5.44445 5.54454 5.5862 5.54454H13.9021C14.0439 5.54454 14.1798 5.60085 14.28 5.70108C14.3803 5.80131 14.4366 5.93724 14.4366 6.07899V10.5576Z"
      fill="#272525"
    />
    <g clipPath="url(#clip0_3217_3749)">
      <path
        d="M14.1268 15.4268C13.812 15.4247 13.5 15.4856 13.209 15.6058C12.9181 15.7261 12.6541 15.9032 12.4326 16.1269C12.2957 16.2623 12.1772 16.415 12.0799 16.5812C12.0799 16.469 12.0799 16.3621 12.0799 16.2498C12.0807 15.4427 11.7853 14.6632 11.2498 14.0592C10.7144 13.4552 9.97593 13.0686 9.17447 12.9726C8.373 12.8766 7.56417 13.078 6.90121 13.5385C6.23826 13.9989 5.76723 14.6866 5.57738 15.4711C5.38752 16.2557 5.49203 17.0826 5.87109 17.7953C6.25016 18.5079 6.87745 19.0568 7.63412 19.3379C8.39079 19.6189 9.22428 19.6127 9.97667 19.3204C10.7291 19.028 11.3481 18.4699 11.7165 17.7516C11.7165 17.7516 11.7165 17.7997 11.7165 17.8265C11.7179 18.462 11.9713 19.0709 12.4212 19.5198C12.8711 19.9687 13.4806 20.2208 14.1161 20.2208C14.7517 20.2179 15.3604 19.9642 15.8098 19.5148C16.2592 19.0654 16.5129 18.4567 16.5158 17.8211C16.5144 17.1875 16.2624 16.5801 15.8148 16.1315C15.3673 15.683 14.7605 15.4296 14.1268 15.4268ZM10.6422 18.099C10.4024 18.3395 10.1176 18.5302 9.80392 18.6604C9.49027 18.7906 9.15403 18.8576 8.81444 18.8576C8.47486 18.8576 8.13861 18.7906 7.82497 18.6604C7.51132 18.5302 7.22644 18.3395 6.98665 18.099C6.61987 17.7381 6.36904 17.2761 6.26625 16.7719C6.16346 16.2677 6.21338 15.7443 6.40961 15.2687C6.60584 14.793 6.93948 14.3867 7.36786 14.1016C7.79624 13.8166 8.2999 13.6658 8.81444 13.6685C9.32458 13.67 9.82286 13.8224 10.2466 14.1064C10.6704 14.3904 11.0007 14.7934 11.1961 15.2646C11.3914 15.7359 11.4431 16.2544 11.3446 16.7549C11.246 17.2554 11.0017 17.7156 10.6422 18.0776V18.099ZM15.3293 19.045C15.0515 19.3232 14.6858 19.4964 14.2945 19.5352C13.9032 19.574 13.5106 19.4759 13.1836 19.2577C12.8565 19.0395 12.6152 18.7147 12.5008 18.3385C12.3864 17.9623 12.406 17.5581 12.5562 17.1948C12.7063 16.8314 12.9779 16.5314 13.3245 16.3458C13.6711 16.1601 14.0713 16.1005 14.457 16.1768C14.8427 16.2532 15.19 16.461 15.4396 16.7647C15.6893 17.0684 15.826 17.4493 15.8263 17.8425C15.8217 18.2861 15.6432 18.7102 15.3293 19.0236V19.045Z"
        fill="#272525"
      />
      <path
        d="M15.9164 17.786C15.9164 18.3524 15.7233 18.8956 15.3797 19.296C15.036 19.6965 14.57 19.9215 14.084 19.9215V15.646C14.5703 15.6472 15.0364 15.8731 15.3799 16.2743C15.7234 16.6755 15.9164 17.2192 15.9164 17.786Z"
        fill="#F4DA95"
      />
      <path
        d="M14.3895 15.646V19.9215C13.7315 19.8965 13.1103 19.6604 12.6553 19.2622C12.2002 18.864 11.9463 18.3345 11.9463 17.7838C11.9463 17.233 12.2002 16.7035 12.6553 16.3054C13.1103 15.9072 13.7315 15.671 14.3895 15.646Z"
        fill="#EDC14F"
      />
      <path
        d="M11.4117 16.1833C11.4117 16.8913 11.1558 17.5702 10.7002 18.0709C10.2446 18.5715 9.62671 18.8527 8.98242 18.8527V13.5083C9.62717 13.5098 10.2451 13.7922 10.7005 14.2937C11.1559 14.7952 11.4117 15.4748 11.4117 16.1833Z"
        fill="#F4DA95"
      />
      <path
        d="M8.98252 13.5083V18.8527C8.19741 18.8214 7.45629 18.5262 6.91333 18.0285C6.37038 17.5308 6.06738 16.8689 6.06738 16.1805C6.06738 15.4921 6.37038 14.8302 6.91333 14.3325C7.45629 13.8348 8.19741 13.5396 8.98252 13.5083Z"
        fill="#EDC14F"
      />
    </g>
    <path
      d="M9.17231 5.55512V11.2042V11.1721C9.11311 11.1117 9.03243 11.0771 8.94785 11.0759H5.5862C5.44446 11.0759 5.30852 11.0196 5.20829 10.9194C5.10806 10.8192 5.05176 10.6832 5.05176 10.5415V6.07888C5.05176 5.93713 5.10806 5.8012 5.20829 5.70097C5.30852 5.60074 5.44446 5.54443 5.5862 5.54443L9.17231 5.55512Z"
      fill="#EDC14F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.87907 8.88313C5.73522 8.91567 5.59257 8.82521 5.56047 8.68107C5.41626 8.03354 5.65272 7.4265 5.88484 7.03613C6.00215 6.83886 6.12724 6.68161 6.22301 6.58675C6.24728 6.56271 6.27298 6.53936 6.29895 6.51942C6.31703 6.50552 6.36274 6.47111 6.42316 6.45407C6.45108 6.44619 6.53267 6.42718 6.62237 6.47124C6.73187 6.52503 6.7668 6.6264 6.77373 6.68768C6.77981 6.74141 6.76904 6.78501 6.76349 6.80471C6.75676 6.8286 6.74809 6.84955 6.74063 6.86575C6.70365 6.94605 6.63206 6.99939 6.55187 7.0161C6.49458 7.08167 6.41891 7.18186 6.34314 7.30929C6.14398 7.6442 5.97961 8.10604 6.08142 8.5632C6.11353 8.70734 6.02293 8.85058 5.87907 8.88313ZM6.62288 6.9439C6.62664 6.94102 6.62613 6.94184 6.62192 6.94466L6.62288 6.9439Z"
      fill="#EDC14F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.24373 9.18787C6.10222 9.27123 5.92169 9.22496 5.8405 9.08451C5.47578 8.45359 5.52698 7.73508 5.64461 7.24566C5.70405 6.99834 5.78485 6.79039 5.85507 6.65801C5.87286 6.62446 5.8924 6.59115 5.91333 6.56132C5.92791 6.54055 5.96499 6.48877 6.0233 6.45022C6.05026 6.4324 6.13033 6.38454 6.23966 6.40017C6.37311 6.41924 6.44328 6.51379 6.47069 6.57579C6.49472 6.63016 6.49762 6.67965 6.49821 6.70223C6.49892 6.72964 6.49661 6.75461 6.49403 6.77417C6.48122 6.87117 6.42297 6.95166 6.34363 6.99663C6.30451 7.0851 6.2573 7.2162 6.2189 7.37596C6.11798 7.79586 6.09544 8.33719 6.35294 8.78263C6.43413 8.92308 6.38523 9.10451 6.24373 9.18787ZM6.39508 6.89651C6.39811 6.8922 6.39785 6.89324 6.39431 6.89764C6.39461 6.89719 6.39487 6.89681 6.39508 6.89651Z"
      fill="white"
    />
    <path
      d="M16.3018 20.7441C16.2386 20.692 16.1593 20.6635 16.0774 20.6635C15.9955 20.6635 15.9161 20.692 15.8529 20.7441C15.7941 20.7815 14.4634 21.7702 13.2074 20.7441C13.1447 20.691 13.0651 20.6619 12.9829 20.6619C12.9008 20.6619 12.8212 20.691 12.7585 20.7441C12.6151 20.8742 12.4861 21.0194 12.3737 21.177C12.1752 20.8668 11.9381 20.5831 11.6682 20.3326C11.605 20.2805 11.5257 20.252 11.4438 20.252C11.3618 20.252 11.2825 20.2805 11.2193 20.3326C11.1391 20.3967 9.14031 21.8771 7.24838 20.3326C7.18519 20.2805 7.10583 20.252 7.02391 20.252C6.942 20.252 6.86264 20.2805 6.79945 20.3326C6.21234 20.9287 5.75052 21.6364 5.44119 22.4138C5.13187 23.1913 4.9813 24.0228 4.99838 24.8593C4.99884 24.9514 5.03529 25.0396 5.09992 25.1052C5.13139 25.1389 5.16946 25.1657 5.21174 25.1841C5.25403 25.2025 5.29965 25.212 5.34576 25.2121H13.015C13.0599 25.2128 13.1046 25.2046 13.1463 25.1881C13.1881 25.1715 13.2262 25.1469 13.2585 25.1157C13.2907 25.0844 13.3165 25.0471 13.3343 25.0058C13.3522 24.9646 13.3617 24.9203 13.3624 24.8754C13.3751 24.6974 13.3751 24.5188 13.3624 24.3409H17.1837C17.275 24.3396 17.3624 24.3035 17.428 24.2399C17.4935 24.1763 17.5323 24.0901 17.5364 23.9989C17.515 23.9187 17.5685 21.9039 16.3018 20.7441ZM5.6985 24.5119C5.7615 23.2379 6.2402 22.0196 7.06132 21.0434C8.87308 22.31 10.7223 21.4335 11.385 21.0434C12.4164 22.1497 12.6142 23.8172 12.6516 24.5226L5.6985 24.5119ZM13.2609 23.6622C13.1644 23.0457 12.9846 22.4452 12.7264 21.8771C12.8096 21.7389 12.9025 21.6068 13.0043 21.4816C13.4539 21.7599 13.9721 21.9073 14.5008 21.9073C15.0295 21.9073 15.5477 21.7599 15.9972 21.4816C16.4798 22.1106 16.7611 22.8706 16.8042 23.6622H13.2609Z"
      fill="#272525"
    />
    <path
      d="M14.4207 21.8876V23.662H13.261C13.1646 23.0455 12.9848 22.445 12.7266 21.8769C12.8098 21.7387 12.9027 21.6066 13.0045 21.4814C13.4281 21.7493 13.9196 21.8903 14.4207 21.8876Z"
      fill="#EDC14F"
    />
    <path
      d="M12.6518 24.5119H9.40771V21.6901C10.1173 21.6274 10.803 21.4025 11.4119 21.0327C12.4166 22.139 12.6144 23.8065 12.6518 24.5119Z"
      fill="#F4DA95"
    />
    <path
      d="M9.24175 21.6369V24.4588H5.53271C5.59572 23.1847 6.07442 21.9664 6.89554 20.9902C7.57531 21.479 8.40755 21.7084 9.24175 21.6369Z"
      fill="#EDC14F"
    />
    <path
      d="M16.804 23.6613H14.4204V21.887C14.984 21.8888 15.5377 21.7393 16.0237 21.4541C16.5018 22.0936 16.774 22.8635 16.804 23.6613Z"
      fill="#F4DA95"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.51697 24.1875C6.37311 24.2201 6.23047 24.1296 6.19837 23.9855C6.05416 23.3379 6.29061 22.7309 6.52274 22.3405C6.64004 22.1433 6.76514 21.986 6.8609 21.8911C6.88517 21.8671 6.91087 21.8438 6.93684 21.8238C6.95493 21.8099 7.00064 21.7755 7.06105 21.7585C7.08897 21.7506 7.17056 21.7316 7.26026 21.7756C7.36976 21.8294 7.40469 21.9308 7.41162 21.9921C7.4177 22.0458 7.40693 22.0894 7.40139 22.1091C7.39465 22.133 7.38599 22.154 7.37853 22.1701C7.34154 22.2504 7.26996 22.3038 7.18977 22.3205C7.13247 22.3861 7.05681 22.4863 6.98103 22.6137C6.78188 22.9486 6.6175 23.4104 6.71932 23.8676C6.75142 24.0117 6.66082 24.155 6.51697 24.1875ZM7.26078 22.2483C7.26453 22.2454 7.26403 22.2462 7.25981 22.2491C7.26019 22.2488 7.26051 22.2485 7.26078 22.2483Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0_3217_3749">
        <rect
          width="11.7577"
          height="8.01664"
          fill="white"
          transform="translate(4.99854 12.4395)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Users;
