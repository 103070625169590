import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Gift = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none">
    <path d="M15.4001 6.83643H2.3457V18.6437H15.4001V6.83643Z" fill="#F4DA95" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.7832 6.83648C1.7832 6.52606 2.03485 6.27441 2.34527 6.27441H15.3996C15.7101 6.27441 15.9617 6.52606 15.9617 6.83648V18.6438C15.9617 18.9542 15.7101 19.2059 15.3996 19.2059H2.34527C2.03485 19.2059 1.7832 18.9542 1.7832 18.6438V6.83648ZM2.90734 7.39855V18.0817H14.8376V7.39855H2.90734Z"
      fill="#777777"
    />
    <path
      d="M16.5763 4.86816H1.11279V7.67943H16.5763V4.86816Z"
      fill="#F4DA95"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.550781 4.86822C0.550781 4.5578 0.802428 4.30615 1.11285 4.30615H16.5764C16.8868 4.30615 17.1385 4.5578 17.1385 4.86822V7.67949C17.1385 7.98991 16.8868 8.24156 16.5764 8.24156H1.11285C0.802428 8.24156 0.550781 7.98991 0.550781 7.67949V4.86822ZM1.67492 5.43029V7.11742H16.0143V5.43029H1.67492Z"
      fill="#777777"
    />
    <path
      d="M12.5511 1.60719C11.8745 1.85804 10.7065 2.88308 9.28857 4.21951C11.2495 4.28006 12.8054 4.28438 13.4734 4.03353C13.643 3.98273 13.8005 3.89751 13.936 3.78315C14.0715 3.66878 14.1821 3.5277 14.2611 3.36867C14.34 3.20963 14.3855 3.03602 14.3949 2.85859C14.4042 2.68117 14.3771 2.50371 14.3153 2.33721C14.2535 2.17072 14.1583 2.01874 14.0356 1.89069C13.9128 1.76264 13.7652 1.66125 13.6018 1.59281C13.4384 1.52437 13.2628 1.49033 13.0857 1.4928C12.9087 1.49528 12.7341 1.53421 12.5726 1.60719H12.5511Z"
      fill="#F4DA95"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.078 0.930845C13.3322 0.927291 13.5845 0.976186 13.819 1.07445C14.0536 1.1727 14.2654 1.3182 14.4414 1.5018C14.6174 1.68541 14.7538 1.90321 14.8423 2.14165C14.9309 2.3801 14.9696 2.63418 14.9562 2.88818C14.9429 3.14218 14.8777 3.39079 14.7646 3.61861C14.6515 3.84644 14.493 4.04867 14.2986 4.21272C14.1092 4.37262 13.8897 4.49289 13.6531 4.56642C13.2223 4.72332 12.5879 4.78286 11.8837 4.80514C11.1472 4.82845 10.2528 4.81167 9.2713 4.78137C9.04421 4.77435 8.84367 4.63131 8.7631 4.41887C8.68254 4.20643 8.7378 3.96638 8.90314 3.81054C9.61376 3.14076 10.2718 2.53939 10.8412 2.07189C11.3936 1.61838 11.9195 1.24197 12.3558 1.08023C12.3787 1.07175 12.402 1.06478 12.4257 1.05936C12.6334 0.977609 12.8543 0.933971 13.078 0.930845ZM13.3847 2.11129C13.2925 2.07266 13.1934 2.05348 13.0937 2.05487C12.9939 2.05627 12.8954 2.07821 12.8043 2.11941C12.7678 2.13588 12.7299 2.14837 12.691 2.15674C12.447 2.2629 12.0689 2.51844 11.5545 2.94073C11.2889 3.1588 10.9998 3.41036 10.69 3.68938C11.1128 3.69421 11.5009 3.69255 11.8481 3.68157C12.5693 3.65875 13.0389 3.59638 13.2759 3.5074C13.2879 3.5029 13.3 3.49881 13.3122 3.49514C13.4079 3.46649 13.4969 3.41838 13.5735 3.35369C13.6502 3.289 13.7129 3.20909 13.7577 3.11884C13.8025 3.02858 13.8284 2.92997 13.8337 2.82912C13.839 2.72827 13.8236 2.62743 13.7885 2.53289C13.7534 2.43834 13.6994 2.35218 13.6299 2.27968C13.5604 2.20719 13.4769 2.14991 13.3847 2.11129Z"
      fill="#777777"
    />
    <path
      d="M8.98682 5.64697H1.88428V6.90555H8.98682V5.64697Z"
      fill="#EDC14F"
    />
    <path d="M8.98664 8.45801H3.1167V17.8693H8.98664V8.45801Z" fill="#EDC14F" />
    <path
      d="M5.3797 1.6074C6.04772 1.85825 7.21998 2.88329 8.66376 4.20242C6.70281 4.26297 5.14697 4.26729 4.47895 4.01644C4.30929 3.96564 4.15184 3.88042 4.01635 3.76606C3.88086 3.65169 3.77021 3.51061 3.69128 3.35158C3.61234 3.19254 3.5668 3.01893 3.55746 2.8415C3.54813 2.66408 3.5752 2.48662 3.63701 2.32012C3.69882 2.15363 3.79405 2.00165 3.91678 1.8736C4.03952 1.74555 4.18716 1.64416 4.35054 1.57572C4.51393 1.50728 4.68958 1.47324 4.86661 1.47571C5.04364 1.47819 5.21828 1.51712 5.3797 1.5901V1.6074Z"
      fill="#F4DA95"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.85881 2.03729C4.75903 2.0359 4.65997 2.05508 4.56777 2.09371C4.47556 2.13233 4.39209 2.18961 4.32261 2.26211C4.25312 2.3346 4.1991 2.42077 4.164 2.51531C4.1289 2.60985 4.11351 2.71069 4.11881 2.81154C4.12412 2.91239 4.15001 3.01101 4.1948 3.10126C4.2396 3.19151 4.30231 3.27142 4.37895 3.33611C4.45559 3.4008 4.54453 3.44891 4.64023 3.47756C4.65249 3.48123 4.66462 3.48532 4.6766 3.48982C4.91356 3.5788 5.3832 3.64117 6.10434 3.66399C6.44423 3.67474 6.82328 3.67656 7.23572 3.6721C6.92734 3.40023 6.6401 3.15428 6.37648 2.94014C5.81602 2.48485 5.42017 2.22254 5.18216 2.13316C5.13766 2.11645 5.09622 2.0945 5.05841 2.06816C4.99375 2.04867 4.92658 2.03824 4.85881 2.03729ZM4.13343 1.05687C4.368 0.958608 4.62024 0.909713 4.87452 0.913267C5.1288 0.916821 5.37958 0.972747 5.61131 1.07752C5.64801 1.09411 5.68232 1.11434 5.71389 1.13767C6.11625 1.32084 6.58897 1.66445 7.08527 2.06761C7.65632 2.53149 8.32107 3.12748 9.04295 3.78704C9.21185 3.94136 9.27036 4.18266 9.1909 4.39721C9.11143 4.61176 8.90985 4.75673 8.68117 4.76379C7.69966 4.7941 6.80532 4.81087 6.06879 4.78756C5.36461 4.76528 4.7302 4.70574 4.29937 4.54884C4.06278 4.47532 3.8433 4.35504 3.65386 4.19514C3.45952 4.0311 3.30095 3.82886 3.18787 3.60103C3.07479 3.37321 3.00959 3.1246 2.99623 2.8706C2.98286 2.6166 3.02162 2.36252 3.11014 2.12407C3.19866 1.88563 3.33509 1.66783 3.51107 1.48423C3.68707 1.30062 3.89887 1.15513 4.13343 1.05687Z"
      fill="#777777"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.30859 18.4791V5.14941H9.43273V18.4791H8.30859Z"
      fill="#777777"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5231 9.44531C12.8756 9.44531 13.1615 9.73113 13.1615 10.0837V12.5596C13.1615 12.5654 13.1614 12.5711 13.1612 12.5769L13.0891 15.236C13.0795 15.5884 12.786 15.8664 12.4336 15.8568C12.0811 15.8473 11.8032 15.5538 11.8127 15.2013L11.8847 12.5509V10.0837C11.8847 9.73113 12.1705 9.44531 12.5231 9.44531Z"
      fill="white"
    />
  </SvgIcon>
);

export default Gift;
