import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Lock = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="22"
    viewBox="0 0 16 22"
    fill="none">
    <g clipPath="url(#clip0_3217_3681)">
      <path
        d="M1.32373 9.18018V14.0393C1.32373 15.8138 2.02863 17.5156 3.28337 18.7703C4.53811 20.0251 6.23989 20.73 8.01436 20.73C9.78882 20.73 11.4906 20.0251 12.7453 18.7703C14.0001 17.5156 14.705 15.8138 14.705 14.0393V9.18018H1.32373Z"
        fill="#F4DA95"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.691406 9.18034C0.691406 8.83103 0.974581 8.54785 1.32389 8.54785H14.7051C15.0545 8.54785 15.3376 8.83103 15.3376 9.18034V14.0395C15.3376 15.9817 14.5661 17.8444 13.1927 19.2177C11.8194 20.5911 9.95673 21.3626 8.01452 21.3626C6.07231 21.3626 4.20965 20.5911 2.8363 19.2177C1.46295 17.8444 0.691406 15.9817 0.691406 14.0395V9.18034ZM1.95638 9.81283V14.0395C1.95638 15.6462 2.59465 17.1871 3.73077 18.3232C4.86689 19.4594 6.4078 20.0976 8.01452 20.0976C9.62124 20.0976 11.1622 19.4594 12.2983 18.3232C13.4344 17.1871 14.0727 15.6462 14.0727 14.0395V9.81283H1.95638Z"
        fill="#777777"
      />
      <path
        d="M1.32373 9.18018V14.0393C1.32373 15.8138 2.02863 17.5156 3.28337 18.7703C4.53811 20.0251 6.23989 20.73 8.01436 20.73C9.78882 20.73 11.4906 20.0251 12.7453 18.7703C14.0001 17.5156 14.705 15.8138 14.705 14.0393V9.18018H1.32373Z"
        fill="#F4DA95"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.691406 9.18034C0.691406 8.83103 0.974581 8.54785 1.32389 8.54785H14.7051C15.0545 8.54785 15.3376 8.83103 15.3376 9.18034V14.0395C15.3376 15.9817 14.5661 17.8444 13.1927 19.2177C11.8194 20.5911 9.95673 21.3626 8.01452 21.3626C6.07231 21.3626 4.20965 20.5911 2.8363 19.2177C1.46295 17.8444 0.691406 15.9817 0.691406 14.0395V9.18034ZM1.95638 9.81283V14.0395C1.95638 15.6462 2.59465 17.1871 3.73077 18.3232C4.86689 19.4594 6.4078 20.0976 8.01452 20.0976C9.62124 20.0976 11.1622 19.4594 12.2983 18.3232C13.4344 17.1871 14.0727 15.6462 14.0727 14.0395V9.81283H1.95638Z"
        fill="#777777"
      />
      <path
        d="M1.95605 9.8125V14.0392C1.95597 15.6333 2.58506 17.163 3.70658 18.2958C4.82811 19.4287 6.35142 20.0731 7.94544 20.0891V9.8125H1.95605Z"
        fill="#EDC14F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.22991 2.20615C5.25127 1.23227 6.60836 0.688965 8.01961 0.688965C9.43087 0.688965 10.788 1.23227 11.8093 2.20615C12.8307 3.18004 13.4379 4.50975 13.505 5.9194C13.5055 5.92942 13.5058 5.93945 13.5058 5.94949V8.53719H12.2408V5.96507C12.1857 4.88551 11.719 3.86787 10.9364 3.12165C10.1503 2.3721 9.10579 1.95394 8.01961 1.95394C6.93343 1.95394 5.88895 2.3721 5.10285 3.12165C4.31675 3.87121 3.84939 4.89462 3.79772 5.97957L2.53418 5.9194C2.60131 4.50975 3.20854 3.18004 4.22991 2.20615Z"
        fill="#777777"
      />
      <path
        d="M8.91642 16.9541C9.01468 16.9546 9.11151 16.9307 9.19824 16.8845C9.28498 16.8384 9.35891 16.7714 9.41341 16.6896C9.46791 16.6079 9.50128 16.5139 9.51053 16.4161C9.51978 16.3183 9.50462 16.2197 9.46641 16.1292L8.77068 14.4517C9.05329 14.2857 9.27344 14.0313 9.39717 13.7278C9.5209 13.4243 9.54134 13.0885 9.45533 12.7723C9.36932 12.456 9.18164 12.1768 8.92125 11.9778C8.66085 11.7787 8.3422 11.6709 8.01444 11.6709C7.68668 11.6709 7.36803 11.7787 7.10763 11.9778C6.84724 12.1768 6.65956 12.456 6.57355 12.7723C6.48754 13.0885 6.50798 13.4243 6.63171 13.7278C6.75544 14.0313 6.97559 14.2857 7.2582 14.4517L6.56247 16.1292C6.52498 16.2197 6.51037 16.3181 6.51994 16.4156C6.5295 16.5132 6.56295 16.6069 6.61731 16.6884C6.67168 16.77 6.74529 16.8369 6.83165 16.8832C6.91801 16.9296 7.01445 16.9539 7.11246 16.9541H8.91642Z"
        fill="#777777"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0099 10.9922C12.3592 10.9922 12.6424 11.2754 12.6424 11.6247V14.0797C12.6397 14.9124 12.3833 15.7246 11.9074 16.408C11.7078 16.6947 11.3136 16.7653 11.027 16.5656C10.7403 16.366 10.6697 15.9718 10.8694 15.6852C11.1982 15.213 11.3754 14.6519 11.3774 14.0766V11.6247C11.3774 11.2754 11.6606 10.9922 12.0099 10.9922Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3217_3681">
        <rect
          width="14.9855"
          height="20.7247"
          fill="white"
          transform="translate(0.507324 0.637695)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Lock;
