import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Rocket = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none">
    <g id="Group 430">
      <path
        id="Vector"
        d="M10.9392 17.3777C10.8716 17.3774 10.8048 17.3637 10.7427 17.3373C10.6805 17.3108 10.6243 17.2722 10.5773 17.2238C10.5302 17.1753 10.4934 17.1179 10.4688 17.055C10.4442 16.9921 10.4325 16.9249 10.4343 16.8574L10.5374 13.6238C10.5414 13.5044 10.5873 13.3902 10.667 13.3011L14.3989 9.1872C14.4468 9.13813 14.5042 9.09939 14.5676 9.07334C14.6311 9.04729 14.6991 9.03449 14.7677 9.03573C14.8357 9.03562 14.9031 9.04983 14.9653 9.07744C15.0619 9.11808 15.1434 9.18804 15.1981 9.27745C15.2529 9.36686 15.2781 9.4712 15.2704 9.57576L14.9038 14.7566C14.8985 14.836 14.8745 14.913 14.8337 14.9813C14.7928 15.0497 14.7364 15.1073 14.6689 15.1495L11.2048 17.3053C11.1251 17.3546 11.0328 17.3797 10.9392 17.3777Z"
        fill="#EDC14F"
      />
      <path
        id="Vector_2"
        d="M14.7674 9.53032L14.4008 14.7221L10.9388 16.8779L11.042 13.6464L14.774 9.53252M14.774 8.5227C14.633 8.52276 14.4936 8.55228 14.3647 8.60937C14.2357 8.66645 14.1202 8.74984 14.0254 8.85418L10.2934 12.9681C10.1333 13.1459 10.0408 13.3744 10.0322 13.6135L9.92244 16.8471C9.92017 17.0277 9.96624 17.2056 10.0559 17.3623C10.1455 17.5191 10.2755 17.649 10.4323 17.7386C10.5891 17.8282 10.767 17.8742 10.9475 17.8718C11.1281 17.8695 11.3047 17.8189 11.4591 17.7252L14.9342 15.5783C15.0699 15.494 15.1837 15.3789 15.2665 15.2424C15.3493 15.1058 15.3987 14.9516 15.4106 14.7924L15.7772 9.60277C15.7929 9.39193 15.742 9.18148 15.6317 9.00109C15.5215 8.82071 15.3573 8.67949 15.1625 8.59734C15.0377 8.54355 14.9033 8.51591 14.7674 8.51611L14.774 8.5227Z"
        fill="#777777"
      />
      <path
        id="Vector_3"
        d="M3.24666 9.69432C3.1565 9.69433 3.06799 9.6702 2.99031 9.62443C2.91264 9.57867 2.84863 9.51294 2.80494 9.43408C2.76125 9.35522 2.73948 9.2661 2.74188 9.17597C2.74428 9.08585 2.77076 8.99801 2.81858 8.92159L4.97433 5.45747C5.01692 5.38967 5.0749 5.33287 5.14355 5.29168C5.21221 5.25048 5.28961 5.22606 5.36948 5.22038L10.5591 4.86475H10.5964C10.6981 4.86515 10.7973 4.89625 10.881 4.95397C10.9647 5.01169 11.029 5.09335 11.0656 5.18824C11.1021 5.28314 11.1091 5.38685 11.0857 5.48581C11.0623 5.58477 11.0096 5.67436 10.9345 5.74285L6.82054 9.47479C6.73212 9.55548 6.61751 9.60148 6.49784 9.60431L3.24666 9.69432Z"
        fill="#F4DA95"
      />
      <path
        id="Vector_4"
        d="M10.5964 5.35869L6.4825 9.09063L3.24668 9.1938L5.40462 5.73188L10.5964 5.36527M10.5964 4.35545H10.524L5.33218 4.71767C5.17291 4.72848 5.01851 4.77714 4.88182 4.85961C4.74513 4.94208 4.6301 5.05598 4.54628 5.19185L2.39053 8.65377C2.29715 8.80645 2.24603 8.98121 2.2424 9.16015C2.23876 9.33909 2.28275 9.51578 2.36986 9.67213C2.45697 9.82848 2.58406 9.95886 2.73813 10.0499C2.8922 10.141 3.06771 10.1895 3.24668 10.1905H3.27961L6.51323 10.0851C6.75233 10.0783 6.98116 9.98641 7.15864 9.82604L11.2726 6.0941C11.4229 5.95712 11.5283 5.77794 11.5751 5.58002C11.6219 5.38211 11.6078 5.17467 11.5348 4.98488C11.4617 4.79509 11.333 4.63178 11.1656 4.51634C10.9982 4.4009 10.7998 4.3387 10.5964 4.33789V4.35545Z"
        fill="#777777"
      />
      <path
        id="Vector_5"
        d="M10.4558 13.7449C10.3893 13.7448 10.3234 13.7316 10.262 13.706C10.2006 13.6804 10.1449 13.6429 10.098 13.5956L6.50216 9.99979C6.40818 9.90484 6.35547 9.77665 6.35547 9.64306C6.35547 9.50947 6.40818 9.38127 6.50216 9.28633L12.8684 2.92008C13.7465 2.04197 15.3491 1.42291 16.7584 1.42291C17.2172 1.41519 17.6737 1.48953 18.1063 1.64243C18.1803 1.66472 18.2484 1.70294 18.3061 1.75439C18.3772 1.81916 18.4282 1.90296 18.4531 1.99587C19.081 3.70378 18.2907 6.11198 17.1777 7.22498L10.8115 13.5912C10.7652 13.639 10.71 13.6772 10.649 13.7035C10.5879 13.7299 10.5223 13.744 10.4558 13.7449Z"
        fill="#F4DA95"
      />
      <path
        id="Vector_6"
        d="M16.765 1.9233C17.1751 1.91758 17.5828 1.98677 17.968 2.12746C18.5431 3.62243 17.8165 5.87257 16.8221 6.86703L10.4558 13.2333L6.85998 9.64183L13.2262 3.27558C13.977 2.527 15.4434 1.92769 16.7606 1.92769M16.7606 0.917873C15.2239 0.917873 13.4809 1.59401 12.5128 2.56212L6.14652 8.92837C5.9579 9.11836 5.85205 9.37522 5.85205 9.64293C5.85205 9.91065 5.9579 10.1675 6.14652 10.3575L9.74235 13.9533C9.83595 14.0472 9.94715 14.1216 10.0696 14.1725C10.192 14.2233 10.3233 14.2494 10.4558 14.2494C10.5884 14.2494 10.7196 14.2233 10.842 14.1725C10.9645 14.1216 11.0757 14.0472 11.1693 13.9533L17.5355 7.58707C18.7824 6.33797 19.632 3.73878 18.9273 1.82451C18.8883 1.70608 18.8289 1.59538 18.7517 1.49742C18.6263 1.33846 18.4568 1.22008 18.2643 1.15716C17.7826 0.989083 17.2752 0.906614 16.765 0.913482L16.7606 0.917873Z"
        fill="#777777"
      />
      <path
        id="Vector_7"
        d="M17.9727 2.13428C18.5457 3.62925 17.819 5.86622 16.8268 6.86945L10.4605 13.2357L8.6626 11.451L17.9727 2.13428Z"
        fill="#EDC14F"
      />
      <path
        id="Vector (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5124 3.57334C14.6994 3.49559 14.9 3.45557 15.1025 3.45557C15.305 3.45557 15.5055 3.49559 15.6925 3.57334C15.8795 3.6511 16.0493 3.76504 16.1921 3.90863L15.6721 4.42577L16.1907 3.90721C16.4059 4.12244 16.5525 4.39666 16.6119 4.69519C16.6713 4.99374 16.6408 5.30318 16.5243 5.5844C16.4078 5.86562 16.2106 6.10598 15.9575 6.27509C15.7044 6.4442 15.4069 6.53447 15.1025 6.53447C14.7981 6.53447 14.5005 6.4442 14.2474 6.27509C13.9944 6.10598 13.7971 5.86562 13.6806 5.5844C13.5641 5.30318 13.5337 4.99373 13.5931 4.69519C13.6524 4.39699 13.7987 4.12304 14.0136 3.90792C14.1562 3.76466 14.3258 3.65097 14.5124 3.57334ZM14.5328 4.42576L15.0513 4.94433C15.0412 4.95444 15.0343 4.96733 15.0315 4.98136C15.0287 4.99539 15.0302 5.00994 15.0356 5.02315C15.0411 5.03637 15.0504 5.04766 15.0623 5.05561C15.0742 5.06356 15.0882 5.0678 15.1025 5.0678C15.1168 5.0678 15.1308 5.06356 15.1427 5.05561C15.1545 5.04766 15.1638 5.03637 15.1693 5.02315C15.1748 5.00993 15.1762 4.99539 15.1734 4.98136C15.1706 4.96733 15.1637 4.95444 15.1536 4.94433L15.1522 4.94291L15.1522 4.94291C15.1457 4.93636 15.1379 4.93116 15.1294 4.92761C15.1209 4.92406 15.1117 4.92223 15.1025 4.92223C15.0932 4.92223 15.0841 4.92406 15.0755 4.92761C15.067 4.93116 15.0593 4.93636 15.0527 4.94291L14.5328 4.42576Z"
        fill="#777777"
      />
      <path
        id="Vector (Stroke)_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29971 12.8242C7.5861 13.1105 7.5861 13.5749 7.29971 13.8613L3.00578 18.1552C2.7194 18.4416 2.25508 18.4416 1.96869 18.1552C1.68231 17.8688 1.68231 17.4045 1.96869 17.1181L6.26262 12.8242C6.54901 12.5378 7.01333 12.5378 7.29971 12.8242Z"
        fill="#777777"
      />
      <path
        id="Vector (Stroke)_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.37224 10.8969C5.65862 11.1833 5.65862 11.6476 5.37224 11.934L2.16496 15.1413C1.87858 15.4277 1.41426 15.4277 1.12787 15.1413C0.84149 14.8549 0.84149 14.3906 1.12787 14.1042L4.33515 10.8969C4.62153 10.6105 5.08585 10.6105 5.37224 10.8969Z"
        fill="#777777"
      />
      <path
        id="Vector (Stroke)_4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.20085 14.7255C9.48724 15.0119 9.48724 15.4762 9.20085 15.7626L5.99358 18.9699C5.70719 19.2563 5.24287 19.2563 4.95649 18.9699C4.6701 18.6835 4.6701 18.2192 4.95649 17.9328L8.16376 14.7255C8.45015 14.4391 8.91447 14.4391 9.20085 14.7255Z"
        fill="#777777"
      />
      <path
        id="Vector (Stroke)_5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2579 6.21929C12.5372 6.51257 12.5259 6.97675 12.2326 7.25607L9.92756 9.45133C9.63428 9.73064 9.17009 9.71932 8.89078 9.42604C8.61146 9.13276 8.62278 8.66858 8.91606 8.38926L11.2211 6.194C11.5144 5.91468 11.9786 5.926 12.2579 6.21929Z"
        fill="white"
      />
    </g>
  </SvgIcon>
);

export default Rocket;
